import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';

import { AuthService } from '@core/services/auth.service';
import { I18nService } from '@core/services/i18n.service';
import { OrganizationViewModel } from '@features/models/organization.model';
import { ProfileViewModel } from '@features/models/profile.model';
import { OrganizationService } from '@features/services/organization.service';
import { ProfileService } from '@features/services/profile.service';
import { RoleService } from '@features/services/role.service';
import { Language } from '@shared/models/language.model';
import { AlertService } from '@shared/services/alert.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  currentLanguage!: string;
  languages: Language[] = [];
  menus = ['assets', 'collections', 'templates'];
  organization?: OrganizationViewModel;
  profile?: ProfileViewModel;
  showMenu = false;

  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    private i18nService: I18nService,
    private organizationService: OrganizationService,
    private profileService: ProfileService,
    private roleService: RoleService,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.initLangs();

    setTimeout(async () => {
      await this.initUser();
    }, 1000);
  }

  onActive(path: string) {
    return this.router.url.includes(path);
  }

  onChange(lang: string) {
    this.i18nService.setLocale(lang);
    localStorage.setItem('lang', lang);
    this.currentLanguage = lang;
  }

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  private initLangs() {
    this.languages = this.i18nService.languages;
    const localLang = localStorage.getItem('lang');

    if (!localLang) {
      this.currentLanguage = this.i18nService.languages[0].value;
      return;
    }

    const lang = this.languages.find(x => x.value === localLang);

    if (lang) {
      this.onChange(lang.value);
    } else {
      this.currentLanguage = this.i18nService.languages[0].value;
    }
  }

  private async initUser() {
    try {
      const { uid, organization } = this.authService;
      if (!uid) return;

      const profile = await lastValueFrom(this.profileService.get(uid));
      if (!profile.data) return;

      this.profile = profile.data;

      if (profile.data.sa) {
        this.authService.setRole('write');
        return;
      }

      if (!organization) return;

      const org = await lastValueFrom(this.organizationService.get(organization));
      if (!org.data) return;

      if (!this.authService.organizationData) {
         this.authService.setOrganization(org.data);
      }

      this.organization = org.data;
      const profileOrg = await lastValueFrom(
        this.profileService.getOrganization(uid, organization)
      );
      if (!profileOrg.data) return;

      const role = await lastValueFrom(this.roleService.get(profileOrg.data.roleId));
      if (!role.data) return;

      if (role.data.permissions.includes('can_write_gallery')) this.authService.setRole('write');
    } catch (error) {
      const translation = this.translate.instant('common.profile-error');
      this.alertService.error(translation);
    }
  }
}
